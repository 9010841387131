import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import blocksImage from "../../assets/img/home/blocks.png";
import {fetchAllIndustries, fetchDemoApplication} from '../../redux/thunks/companiesThunks';
import CheckBoxBlueStyle from '../base/CheckBoxBlueStyle';
import InputGroup from '../base/InputGroup';
import SelectBox from '../base/SelectBox';

const RequestDemoHero = ({getValue}) => {
    return (
        <section
            className={`py-[56px] bg-top bg-no-repeat font-['Raleway']`}
            style={{backgroundImage: `url(${blocksImage})`}}
        >
            <div className="container">
                <div className="flex flex-col items-center text-center gap-[17px] text-[18px] mb-[69px]">
                    <h3 className="text-[45px] max-md:text-[32px] font-bold max-w-[830px]">
                        {getValue('demo_top_title')}
                    </h3>
                    <p>{getValue('demo_top_desc')}</p>
                </div>
                <div className="flex gap-[30px] font-medium max-lg:flex-col-reverse">
                    <div
                        className="flex-1  rounded-[20px] bg-[#EBF0F3] flex flex-col px-[50px] py-[70px] gap-[17px] max-md:p-[20px]">
                        <div className="flex-auto text-black">
                            <div className='italic text-[22px] mb-[17px] max-md:text-[16px]'>
                                {getValue('demo_top_section_text')}
                            </div>
                            <h5 className='font-semibold text-[24px] mb-[6px] max-md:text-[18px]'>{getValue('demo_top_section_text_name')}</h5>
                            <p className='italic text-[20px] max-md:text-[16px]'>{getValue('demo_top_section_text_job')}</p>
                        </div>
                        <div className="">
                            <h5 className='font-semibold text-[24px] mb-[6px] max-md:text-[18px]'>{getValue('demo_top_section_about')}</h5>
                            <p className='italic text-[20px] max-md:text-[16px]'>
                                {getValue('demo_top_section_about_text')}
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex-1 px-[44px] py-[34px] border rounded-[20px] bg-white border-[#676767] max-md:p-[20px]">
                        <RequestDemoForm getValue={getValue}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default RequestDemoHero


function RequestDemoForm({onSuccess, getValue}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [agree, setAgree] = React.useState(false)

    const [industryOptions, setIndustryOptions] = React.useState([
    ])

    useEffect(() => {
        const req = async () => {
            const res = await dispatch(fetchAllIndustries())

            if (res?.payload && res.payload.length > 0) {
                setIndustryOptions([ ...res.payload.map(el => ({
                    label: el.name,
                    value: el.id
                }))])
            }
        }

        req()
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        companyURL: '',
        companyEmployees: '',
        jobTitle: '',
        industry: ""
    })

    const [formErrors, setFormErrors] = useState({})

    const handleChangeForm = (e) => {
        const {value, id} = e.target;
        setFormErrors(prevState => ({...prevState, [id]: false}));
        setFormData(prevState => ({...prevState, [id]: value}))
    }

    console.log("     !!!!!!!!!!!!!!!!!!", formData)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const fields = Object.entries(formData)
        let anyErrors = false

        fields.forEach(el => {
            if (!el[1]) {
                setFormErrors(prevState => ({...prevState, [el[0]]: true}))
                anyErrors = true
            }
        })
        if (!agree) {
            setFormErrors(prevState => ({...prevState, agree: true}))
            return
        }

        if (anyErrors) return

        const res = await dispatch(fetchDemoApplication(formData))
        navigate('/HOM1_5_1', {
            state: res.payload
        })
        // console.log(res, 'res application')

        // if (res.payload?.message) {
        //     onSuccess && onSuccess(res?.payload?.message)
        // }

        // console.log("as");
    }


    return (
        <form className="flex flex-col gap-[30px]" onSubmit={handleSubmit}>
            <InputGroup
                label={getValue('demo_top_form_name')}
                placeholder={getValue('demo_top_form_name_placeholder')}
                id="name"
                onChange={handleChangeForm}
                value={formData.name}
                error={formErrors['name']}
            />
            <InputGroup
                label={getValue('demo_top_form_email')}
                placeholder={getValue('demo_top_form_email_placeholder')}
                type="email"
                id="email"
                onChange={handleChangeForm}
                value={formData.email}
                error={formErrors['email']}
            />
            <InputGroup
                label={getValue('demo_top_form_phone')}
                type="tel"
                id="phoneNumber"
                onChange={handleChangeForm}
                value={formData.phoneNumber}
                error={formErrors['phoneNumber']}
                placeholder={getValue("demo_top_form_phone_number_placeholder")}
            />
            <InputGroup
                label={getValue('demo_top_form_company')}
                placeholder={getValue('demo_top_form_company_placeholder')}
                id="companyURL"
                onChange={handleChangeForm}
                value={formData.companyURL}
                error={formErrors['companyURL']}
            />
            <SelectBox options={[
                {value: '1-10', label: '1-10 Employees'},
                {value: '11-30', label: '11-30 Employees'},
                {value: '31-50', label: '31-50 Employees'},
                {value: '51-75', label: '51-75 Employees'},
                {value: '76-100', label: '76-100 Employees'},
                {value: '101-150', label: '101-150 Employees'},
                {value: '151-200', label: '151-200 Employees'},
                {value: '200+', label: '200+ Employees'},
            ]} label="Company # of Employees"
                       onSelect={val => {
                           setFormData(prevState => ({...prevState, 'companyEmployees': val}))
                           setFormErrors(prevState => ({...prevState, 'companyEmployees': false}));

                       }}
                       className={'!mt-[10px] h-[74px]'}
                       value={formData.companyEmployees} svg={2}
                       error={formErrors.companyEmployees}
                       placeholder="choose # of employees"
            />

            <InputGroup label={getValue('demo_top_form_message')} id="jobTitle" parentClassName={'!mt-[25px]'}
                        onChange={handleChangeForm}
                        value={formData.jobTitle}
                        error={formErrors['jobTitle']}
                        placeholder={getValue("demo_top_form_job_title_placeholder")}
            />

            <SelectBox options={industryOptions} label={getValue('demo_top_form_message_placeholder')}
                       onSelect={val => {
                           setFormData(prevState => ({...prevState, 'industry': val}))
                           setFormErrors(prevState => ({...prevState, 'industry': false}));
                       }}
                       className={'h-[74px]'}
                       value={formData.industry} svg={2}
                       error={formErrors['industry']}
                       placeholder={"choose industry"}
            />

            <CheckBoxBlueStyle
                checked={agree}
                onChange={() => setAgree(!agree)}
                text={
                    <p className='leading-7' dangerouslySetInnerHTML={{__html: getValue('demo_top_form_terms')}}/>
                }
            />

            <div className="flex justify-center flex-col items-center">
                {formErrors?.agree && <p className="text-red-500 text-sm mt-1">You must agree the Privacy Policy</p>}
                <button type="submit" className={`w-full px-[10px] py-[14px] text-[30px] font-bold bg-[#0056B3] hover:bg-green-600 rounded-md text-white max-md:text-[18px]
                 ${!formErrors?.agree ? 'opacity-60' : ''}`}
                >
                    {getValue('demo_top_form_btn')}
                </button>
            </div>
        </form>
    )
}

